<template>
	<div class="App">
		<TopBar
			brand-name="AE Lab"
			:brand-image-path="brandImagePath"
			:nav-end-items="navEndItems"
		/>
		<Home />
	</div>
</template>

<script>
export default {
	name: "App",
	components: {
		TopBar: () => import("./../../../components/TopBar.vue"),
		Home: () => import("./view/Home.vue"),
	},
	data() {
		return {
			navEndItems: [
				{
					text: "Home",
				},
				{
					text: "Apps",
					href: "#apps",
				},
				{
					text: "Bio",
					href: "#bio",
				},
				{
					text: "Contatc me",
					href: "#contactMe",
				},
			],
			brandImagePath: require(`./../../../img/aeLogo.png`),
		}
	},
	mounted() {
		const spinner = document.querySelector(".spinner")
		spinner.parentNode.removeChild(spinner)
	},
}
</script>  

<style lang="scss">
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #23707c;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$secondary: #ffc502;
$secondary-invert: findColorInvert($secondary);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
	"white": (
		$white,
		$black,
	),
	"black": (
		$black,
		$white,
	),
	"light": (
		$light,
		$light-invert,
	),
	"dark": (
		$dark,
		$dark-invert,
	),
	"primary": (
		$primary,
		$primary-invert,
	),
	"secondary": (
		$secondary,
		$secondary-invert,
	),
	"info": (
		$info,
		$info-invert,
	),
	"success": (
		$success,
		$success-invert,
	),
	"warning": (
		$warning,
		$warning-invert,
	),
	"danger": (
		$danger,
		$danger-invert,
	),
	"twitter": (
		$twitter,
		$twitter-invert,
	),
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import "~bulma";

.fullWindowHeight {
	min-height: 100vh;
}

.pointerEventNone {
	pointer-events: none;
}
</style>


