var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "App" },
    [
      _c("TopBar", {
        attrs: {
          "brand-name": "AE Lab",
          "brand-image-path": _vm.brandImagePath,
          "nav-end-items": _vm.navEndItems
        }
      }),
      _c("Home")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }